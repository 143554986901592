<template>
    <div class="categoriesPage">
        <HeadBar titleName="热门分类"></HeadBar>
        <PullRefresh v-if="!isLoading" :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
            @onRefresh="onRefresh" :error="error" :is-higeht-max="true" :adv-list="advList" :hasAdvPagination="true"
            className="TopicPage">

            <div class="content oepnX">
             <div class="showTab" ref="contentTab">
                <div class="labelTitle" v-show="showLabelTitle">
                 <span>{{labelName}}</span>
                </div>
                <div class="classify" v-show="!showLabelTitle">
                    <div class="classifyItem">
                        <div class="classifyType">
                            <span>{{tagTypeData.name}}</span>
                        </div>
                        <div class="classifyTypeValue">
                            <div v-for="(item,index) in tagTypeData.tags" :key="index">
<!--                                <div class="classifyLabel" v-if="!tagTypeData.isAn && index < 4"-->
<!--                                    @click="onTagLabel(tagTypeData,item,index)"-->
<!--                                    :class="{classifyItemActive:tagTypeData.activeIndex == index}">-->
<!--                                    <span>{{item.name}}</span>-->
<!--                                </div>-->
                                <div class="classifyLabel" @click="onTagLabel(tagTypeData,item,index)"
                                    :class="{classifyItemActive: tagTypeData.activeIndex == index}">
                                    <span>{{item.name}}</span>
                                </div>
                            </div>
<!--                            <div class="unfoldBtn" @click="switchTag(tagTypeData)">-->
<!--                                <span>{{tagTypeData.isAn?"收起":"展开"}}</span>-->
<!--                            </div>-->
                        </div>
                    </div>

                    <div class="classifyItem" v-for="(item,index) in resultList" :key="index">
                        <div class="classifyType">
                            <span>{{item.name}}</span>
                        </div>
                        <div class="classifyTypeValue">
                            <div v-for="(itemTag,indexTag) in item.tags" :key="indexTag">
<!--                                <div class="classifyLabel" v-if="!item.isAn && indexTag < 4"-->
<!--                                    @click="onTagLabelCondition(item,itemTag,indexTag)"-->
<!--                                    :class="{classifyItemActive:item.activeIndex == indexTag}">-->
<!--                                    <span>{{itemTag.name}}</span>-->
<!--                                </div>-->
                                <div class="classifyLabel"
                                    @click="onTagLabelCondition(item,itemTag,indexTag)"
                                    :class="{classifyItemActive:item.activeIndex == indexTag}">
                                    <span>{{itemTag.name}}</span>
                                </div>
                            </div>
<!--                            <div class="unfoldBtn classifyLabel" @click="switchTag(item)">-->
<!--                                <span>{{item.isAn?"收起":"展开"}}</span>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="bottom" @click="changeSticky">
                 <svg-icon class="isShow" iconClass="isShow" v-show="!showLabelTitle" />
                 <svg-icon class="isShow" iconClass="noShow" v-show="showLabelTitle" />
                </div>
             </div>
<!--                <van-sticky offset-top="1.2rem" @change="changeSticky">-->
<!--                    <div></div>-->
<!--                </van-sticky>-->
                <!-- <van-sticky class="labelTitle">
                  <div>123123123</div>
                </van-sticky> -->
                <div class="contentVideo" :style="{paddingTop: showLabelTitle ? '100px' : (dynamicPadding + 30) + 'px' }">
                    <VerticleThreeList :isAcg="tagTypeData.activeIndex!==2" :videoList="videoList" v-if="isVertical"></VerticleThreeList>
                    <TransverselyTwoList :isAcg="tagTypeData.activeIndex!==2" :videoList="videoList" v-else></TransverselyTwoList>
                    <!-- 猜你想看 -->
                    <div v-if="videoHotList && videoHotList.length > 0" class="guess">
                        <div class="guessTitle">
                            <span>猜你想看</span>
                        </div>
                        <VerticleThreeList :isAcg="tagTypeData.activeIndex!==2" :videoList="videoHotList" v-if="isVertical"></VerticleThreeList>
                        <TransverselyTwoList :isAcg="tagTypeData.activeIndex!==2" :videoList="videoHotList" v-else></TransverselyTwoList>
                    </div>
                </div>
            </div>
        </PullRefresh>
        <Loading v-else />
    </div>
</template>

<script>
    import {AdType, getAdItem} from "@/utils/getConfig";
    import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
    import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
    import HeadBar from "@/components/HeadBar";
    import PullRefresh from "@/components/PullRefresh";
    import Loading from "@/components/Loading";
    import {
        queryHotkindTagsList,
        queryTagsmediaList,
        queryHotInfoList
    } from "@/api/video"
    export default {
        name: "categoriesPage",
        components: {
            HeadBar,
            PullRefresh,
            Loading,
            VerticleThreeList,
            TransverselyTwoList
        },
        data() {
            return {
                tagTypeData: {
                    name: "类型",
                    isAn: false,
                    activeIndex: 0,
                    parTages: null,
                    tags: [{
                            name: "动漫",
                            kind: 0
                        },
                        {
                            name: "漫画",
                            kind: 1
                        },
                        {
                            name: "真人",
                            kind: 3
                        },
                        {
                            name: "写真",
                            kind: 5
                        },
                        {
                            name: "小说",
                            kind: 4
                        },
                        {
                            name: "原作",
                            kind: 6
                        }
                    ]
                },
                resultList: [],
                pageNumber: 1,
                pageSize: 20,
                isLoading: true,
                loading: true,
                refreshing: false,
                finished: false,
                isNoData: false,
                error: false,
                advList: [],
                videoList: [],
                format: 0,
                showLabelTitle: false,
                tagTitle: null,
                labelName: "动漫",
                videoHotList: [],
                oldVideoList: [],
                listAdvs: [],
                listAdvsVerticel: [],
                isVertical: true,
                isTypeLoading : 0,   //书籍加载，如果加载完，就加载推荐（0：加载书籍数据，1：加载推荐数据）
                dynamicPadding: 0,
            }
        },
        created() {
            this.listAdvs = getAdItem(AdType.CLASSIFICARION);
            this.listAdvsVerticel = getAdItem(AdType.CLASSIFICARION_VERTICLE);
            // console.log(this.listAdvs,"----------------")
            // console.log(this.listAdvsVerticel,"+++++++++++++++")
            // console.log(this.listAdvs)
        },
        watch: {
         showLabelTitle() {
          this.$nextTick(() => {
           this.updatePadding();
          });
         }
        },
        mounted() {
            this.parTages = new Set();
            this.tagTitle = new Set();
            this.getHotkindTagsList();
            this.getTagsmediaList();
            setTimeout(() => {
             this.updatePadding();
             window.addEventListener('resize', this.updatePadding);
            }, 1000);
        },
        beforeDestroy() {
         window.removeEventListener('resize', this.updatePadding);
        },
        methods: {
            updatePadding() {
             const tab = this.$refs.contentTab;
             if (tab) {
              this.dynamicPadding = tab.offsetHeight;
             }
             console.log(tab, this.dynamicPadding);
            },
            async getHotkindTagsList() {
                let req = {
                    kind: this.tagTypeData.tags[this.tagTypeData.activeIndex].kind
                }
                let res = await this.$Api(queryHotkindTagsList, req);

                if (res && res.code == 200) {
                    res.data.list.forEach((iterator) => {
                        iterator.isAn = false;
                    })
                    this.resultList = res.data.list;
                }
            },
            changeSticky() {
                this.showLabelTitle = !this.showLabelTitle;
            },
            switchTag(item) {
                item.isAn = !item.isAn;
            },
            onTagLabel(tagTypeData, item, index) {
                console.log(tagTypeData, item, index);
                tagTypeData.activeIndex = index;
                this.isVertical = item.kind != 3;
                // this.labelName = tagTypeData.tag[index].name;
                this.labelName = this.tagTypeData.tags[this.tagTypeData.activeIndex].name;
                this.parTages.clear();
                this.tagTitle.clear();
                this.videoHotList = [];
                this.isTypeLoading = 0;
                this.getHotkindTagsList();
                this.pageNumber = 1;
                this.finished = false;
                this.isNoData = false;
                this.getTagsmediaList('refresh');
                setTimeout(() => {
                 this.updatePadding();
                }, 1000);
            },
            onTagLabelCondition(tagTypeData, item, index) {
                this.isTypeLoading = 0;
                this.videoHotList = [];
                if (tagTypeData.activeIndex == index) {
                    tagTypeData.activeIndex = undefined;
                    this.parTages.delete(item.id)
                    this.tagTitle.delete(item.name);

                } else {

                    if (tagTypeData.activeIndex != undefined) {
                        this.parTages.delete(tagTypeData.tags[tagTypeData.activeIndex].id)
                        this.tagTitle.delete(tagTypeData.tags[tagTypeData.activeIndex].name);
                        this.parTages.add(item.id);
                        this.tagTitle.add(item.name);
                    } else {
                        this.parTages.add(item.id);
                        this.tagTitle.add(item.name);
                    }

                    tagTypeData.activeIndex = index;
                    // console.log(this.tagTitle)

                }
                // console.log(this.tagTitle)
                if (Array.from(this.tagTitle).length > 0) {
                    this.labelName = this.tagTypeData.tags[this.tagTypeData.activeIndex].name + "•" + Array.from(
                        this.tagTitle).join("•");
                } else {
                    this.labelName = this.tagTypeData.tags[this.tagTypeData.activeIndex].name;
                }
                this.$forceUpdate();
                this.pageNumber = 1;
                this.finished = false;
                this.isNoData = false;
                this.getTagsmediaList('refresh');
                setTimeout(() => {
                 this.updatePadding();
                }, 1000);
            },
            async getTagsmediaList(type) {
                if (type) {
                    this.isTypeLoading = 0;
                }
                let req = {
                    kind: this.tagTypeData.tags[this.tagTypeData.activeIndex].kind,
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize
                }
                if (this.parTages.size > 0) {
                    req.tags = Array.from(this.parTages)
                }

                let res = await this.$Api(queryTagsmediaList, req);

                this.loading = false;
                this.refreshing = false;
                this.isLoading = false;

                try {
                    if (res && res.code == 200) {
                        if (res.data.MediaList) {
                            if (type == "refresh") {
                                this.oldVideoList = res.data.MediaList || [];
                            } else {
                                this.oldVideoList = this.oldVideoList.concat(res.data.MediaList);
                            }

                            if (this.listAdvs.length > 0 || this.listAdvsVerticel.length > 0) {
                            // this.oldVideoList = this.oldVideoList.concat(list);
                            let advNum = Math.floor(this.oldVideoList.length / 4);
                            this.videoList = JSON.parse(JSON.stringify(this.oldVideoList));
                            let advLength = this.isVertical ? this.listAdvs.length : this.listAdvsVerticel.length;
                            for (let i = 0; i < advNum; i++) {
                                let advItem =  this.isVertical ? this.listAdvs[i % advLength] : this.listAdvsVerticel[i     % advLength];
                                this.videoList.splice(5 * i + 4, 0, {
                                    adv: advItem
                                });
                            }
                        } else {
                            this.videoList = [].concat(this.oldVideoList);
                        }
                        } else if (this.pageNumber == 1) {
                            this.videoList = []
                        }
                        if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
                            // this.finished = true;
                            this.isTypeLoading = 1;
                            this.getHotInfoList('refresh');
                        }
                    } else {
                        this.error = true;
                    }
                } catch (error) {
                    this.error = true;
                    this.loading = false;
                }
            },
            async getHotInfoList(type) {
                /**
                 * 猜你喜欢
                 * @type {{pageNumber: number, kind: number, pageSize: number}}
                 */
                let req = {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    kind: this.tagTypeData.tags[this.tagTypeData.activeIndex].kind,
                }
                let res = await this.$Api(queryHotInfoList, req);

                this.loading = false;
                this.refreshing = false;
                this.isLoading = false;

                if (res && res.code == 200) {

                    if (res.data.MediaList && res.data.MediaList.length > 0) {
                        if (type == "refresh") {
                            this.videoHotList = res.data.MediaList;
                        } else {
                            this.videoHotList = this.videoHotList.concat(res.data.MediaList);
                        }
                    }



                    // if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
                    //     this.isNoData = true;
                    // }
                    if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
                        this.finished = true;
                    }
                }
            },
            onRefresh() {
                this.isTypeLoading = 0;
                this.pageNumber = 1;
                this.refreshing = true;
                this.finished = false;
                this.isNoData = false;
                this.loading = true;
                this.videoHotList = [];
                this.getTagsmediaList('refresh');
            },
            onLoad() {
                this.loading = true;
                if (this.error) {
                    this.error = false;
                } else {
                    this.pageNumber++
                }
                if(this.isTypeLoading == 0){
                    this.getTagsmediaList();
                }else{
                    this.getHotInfoList();
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
    .categoriesPage {
        height: 100%;

        .content {
            height: calc(100% - 44px);
            //padding: 0 12px;
            //margin-top: 18px;
            .showTab {
             width: 100%;
             height: auto;
             margin-bottom: 12px;
             padding-bottom: 18px;
             background: #fff;
             position: fixed;
             z-index: 9;

             .labelTitle {
              width: 100%;
              height: 28px;
              font-size: 15px;
              line-height: 42px;
              text-align: center;
              font-weight: 900;
              //display: flex;
              //align-items: center;
              //justify-content: center;
              color: rgba(176, 159, 217, 1);
              background: #fff;
              //position: fixed;
              //top: 42px;
              //z-index: 2;
              // position: fixed;
              // top: -10000px;
              // /deep/ .van-sticky--fixed {
              //     height: 42px;
              //     line-height: 42px;
              //     width: 100%;
              //     font-size: 15px;
              //     text-align: center;
              //     display: block !important;
              //     top: 45px;
              //     background: rgba(255, 255, 255, 0.81);
              // }
             }


             .bottom {
              width: 80px;
              height: 22px;
              position: absolute;
              bottom: -22px;
              left: 50%;
              transform: translateX(-50%);
              background: #fff;
              z-index: 99;
              display: flex;
              justify-content: center;
              border-radius: 0 0 20px 20px;
              .isShow {
               margin-top: 2px;
               width: 17.04px;
               height: 10.62px;
              }
             }
            }

            .classify {

                .classifyItem {
                    display: flex;
                    font-size: 14px;
                    color: #000;
                    font-weight: 600;

                    .classifyType {
                        width: 85px;
                        //margin-right: 10px;
                        margin-top: 18px;
                        margin-left: 16px;
                        font-weight: bolder;
                    }
                    .classifyType:last-child {
                      margin-right: 0;
                    }

                    .classifyTypeValue {
                        display: flex;
                        //flex-wrap: wrap;
                        //width: calc(100% - 16px);
                        width: 100%;
                        overflow-x: auto;

                        .classifyLabel {
                           //width: 30px;
                           height: 26px;
                           line-height: 26px;
                           padding: 0 14px;
                           margin-right: 14px;
                           margin-top: 16px;
                           background: rgba(245, 245, 245, 1);
                           border-radius: 8px;
                           color: #999999;
                           font-weight: 600;
                           white-space: nowrap;
                        }
                        .classifyItemActive {
                         color: rgba(176, 159, 217, 1) !important;
                         padding: 0 14px;
                         font-weight: bolder;
                         line-height: 24px;
                         border: 2px solid rgba(0, 0, 0, 0.05);
                         background: #fff;
                         box-sizing: border-box;
                        }
                    }

                    .unfoldBtn {
                        //margin-right: 30px;
                        margin-top: 18px;
                        font-weight: bolder;
                        color: #999999;
                    }
                }
            }

            .contentVideo {
                padding: 0 12px;
                padding-top: 80px;
            }
        }
        .guess{

            .guessTitle{
                color: rgb(0,0,0);
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 9px;
            }
        }
    }
</style>
